import React from 'react';

import { NextPageContext } from 'next';
interface Props {
    statusCode: number;
}
import DefaultErrorPage from 'next/error'

const Error = ({ statusCode }: Props) => {
  if(statusCode == 404){
    return(
      <DefaultErrorPage statusCode={404} title="ファイルがありません. URLを確認してください"/>
    )    
  } else if (statusCode == 403){
    return(
      <DefaultErrorPage statusCode={403} title="アクセス権がありません. 招待を受けてから再度ご利用ください"/>
    )
  } else if (statusCode == 500){
    return(
      <DefaultErrorPage statusCode={500} title="サーバーでエラーが発生しました. しばらくしてから再度お試しください"/>
    )
  } else {
    return <DefaultErrorPage statusCode={statusCode} />
  }
};

Error.getInitialProps = async ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;